/* GENERATED FILE */
import React, { forwardRef, useContext } from "react";
import { IconProps, IconContext } from "../lib";

const renderPathFor = (
  weight: string,
  color: string
): React.ReactNode | null => {
  switch (weight) {
    case "bold":
      return (
        <>
          <path
            d="M240.00526,60.66136A127.734,127.734,0,0,1,207.91934,92.591l-.0001-.00015A128.04082,128.04082,0,0,1,17.97784,199.99759l.0065-.01172a127.3714,127.3714,0,0,0,70.01685-22.14062l-.00076.00111A128.2051,128.2051,0,0,1,36.64049,37.72648l-.00039.00025A127.98517,127.98517,0,0,0,128.82832,95.1112l-.0028.00058a40.009,40.009,0,0,1,74.03809-27.81035l.00013.00442a128.63506,128.63506,0,0,0,37.14152-6.64449"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="24"
          />
        </>
      );
    case "duotone":
      return (
        <>
          <path
            d="M240.00526,60.66136A127.734,127.734,0,0,1,207.91934,92.591l-.0001-.00015A128.04082,128.04082,0,0,1,17.97784,199.99759l.0065-.01172a127.3714,127.3714,0,0,0,70.01685-22.14062l-.00076.00111A128.2051,128.2051,0,0,1,36.64049,37.72648l-.00039.00025A127.98517,127.98517,0,0,0,128.82832,95.1112l-.0028.00058a40.009,40.009,0,0,1,74.03809-27.81035l.00013.00442a128.63506,128.63506,0,0,0,37.14152-6.64449"
            opacity="0.2"
          />
          <path
            d="M240.00526,60.66136A127.734,127.734,0,0,1,207.91934,92.591l-.0001-.00015A128.04082,128.04082,0,0,1,17.97784,199.99759l.0065-.01172a127.3714,127.3714,0,0,0,70.01685-22.14062l-.00076.00111A128.2051,128.2051,0,0,1,36.64049,37.72648l-.00039.00025A127.98517,127.98517,0,0,0,128.82832,95.1112l-.0028.00058a40.009,40.009,0,0,1,74.03809-27.81035l.00013.00442a128.63506,128.63506,0,0,0,37.14152-6.64449"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
        </>
      );
    case "fill":
      return (
        <>
          <path d="M246.41211,55.87061a7.99856,7.99856,0,0,0-8.95459-2.793,120.50746,120.50746,0,0,1-30.34131,6.01465,48.00284,48.00284,0,0,0-87.11963,26.51123A119.68665,119.68665,0,0,1,43.3457,33.36279a8.00016,8.00016,0,0,0-14.41455,2.22657A136.21055,136.21055,0,0,0,73.793,177.19482a119.46448,119.46448,0,0,1-55.93018,14.792,8.00707,8.00707,0,0,0-3.76709,15.00586A136.02811,136.02811,0,0,0,215.71631,96.85742a135.60493,135.60493,0,0,0,30.84228-31.60791A7.99891,7.99891,0,0,0,246.41211,55.87061Z" />
        </>
      );
    case "light":
      return (
        <>
          <path
            d="M240.00526,60.66136A127.734,127.734,0,0,1,207.91934,92.591l-.0001-.00015A128.04082,128.04082,0,0,1,17.97784,199.99759l.0065-.01172a127.3714,127.3714,0,0,0,70.01685-22.14062l-.00076.00111A128.2051,128.2051,0,0,1,36.64049,37.72648l-.00039.00025A127.98517,127.98517,0,0,0,128.82832,95.1112l-.0028.00058a40.009,40.009,0,0,1,74.03809-27.81035l.00013.00442a128.63506,128.63506,0,0,0,37.14152-6.64449"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="12"
          />
        </>
      );
    case "thin":
      return (
        <>
          <path
            d="M240.00526,60.66136A127.734,127.734,0,0,1,207.91934,92.591l-.0001-.00015A128.04082,128.04082,0,0,1,17.97784,199.99759l.0065-.01172a127.3714,127.3714,0,0,0,70.01685-22.14062l-.00076.00111A128.2051,128.2051,0,0,1,36.64049,37.72648l-.00039.00025A127.98517,127.98517,0,0,0,128.82832,95.1112l-.0028.00058a40.009,40.009,0,0,1,74.03809-27.81035l.00013.00442a128.63506,128.63506,0,0,0,37.14152-6.64449"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="8"
          />
        </>
      );
    case "regular":
      return (
        <>
          <path
            d="M240.00526,60.66136A127.734,127.734,0,0,1,207.91934,92.591l-.0001-.00015A128.04082,128.04082,0,0,1,17.97784,199.99759l.0065-.01172a127.3714,127.3714,0,0,0,70.01685-22.14062l-.00076.00111A128.2051,128.2051,0,0,1,36.64049,37.72648l-.00039.00025A127.98517,127.98517,0,0,0,128.82832,95.1112l-.0028.00058a40.009,40.009,0,0,1,74.03809-27.81035l.00013.00442a128.63506,128.63506,0,0,0,37.14152-6.64449"
            fill="none"
            stroke={color}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="16"
          />
        </>
      );
    default:
      console.error(
        'Unsupported icon weight. Choose from "thin", "light", "regular", "bold", "fill", or "duotone".'
      );
      return null;
  }
};

const TwitterLogo = forwardRef<SVGSVGElement, IconProps>((props, ref) => {
  const { color, size, weight, mirrored, children, ...restProps } = props;
  const {
    color: contextColor,
    size: contextSize,
    weight: contextWeight,
    mirrored: contextMirrored,
    ...restContext
  } = useContext(IconContext);

  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? contextSize}
      height={size ?? contextSize}
      fill={color ?? contextColor}
      viewBox="0 0 256 256"
      transform={mirrored || contextMirrored ? "scale(-1, 1)" : undefined}
      {...restContext}
      {...restProps}
    >
      {children}
      <rect width="256" height="256" fill="none" />
      {renderPathFor(weight ?? contextWeight, color ?? contextColor)}
    </svg>
  );
});

TwitterLogo.displayName = "TwitterLogo";

export default TwitterLogo;
